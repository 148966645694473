<template>
  <div
    slot='content'
    class='px-2 lg:px-8 mt-8'>
    <div class='flex flex-row gap-x-4'>
      <h1 class='py-2 text-lg lg:text-xl font-semibold'>{{ clientName }}</h1>
    </div>
    <button
      @click='exportExcel'
      class='block border px-4 py-2 text-gray-700 text-sm font-semibold rounded-md hover:border-blue-300 hover:shadow-md'>
      {{ $t('excelDownload') }}
    </button>
    <div class='mt-8'>
      <table
        id='clientAnnualRevenue'
        class='table-fixed'>
        <thead>
          <tr class='whitespace-no-wrap border-b border-gray-200 text-sm uppercase'>
            <th class='py-2 px-3 text-left'>{{ $t('caseId') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('caseName') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('client') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('counterpart') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('billingDate') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('depositDate') }}</th>
            <th class='py-2 px-3 text-right'>{{ $t('invoiceId') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('billingAmount') }}</th>
            <th class='py-2 px-3 text-right'>{{ $t('totalDepositAmount') }}</th>
            <th class='py-2 px-3 text-right'>{{ $t('expenseAmount') }}</th>
            <th class='py-2 px-3 text-right'>{{ $t('associateUsedAmount') }}</th>
            <th class='py-2 px-3 text-right'>{{ $t('anticipatedProfit') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='summary in selectedClientPerformances'
            :key='`${summary.investment_id}-${summary.casenum}-${summary.invoice_id}`'
            class='border-b border-gray-200 text-xs'>
            <td class='py-2 px-3 whitespace-no-wrap'>{{ summary.casenum }}</td>
            <td class='py-2 px-3'>{{ summary.investment_name }}</td>
            <td class='py-2 px-3'>{{ summary.entity_names }}</td>
            <td class='py-2 px-3'>{{ summary.counterparty }}</td>
            <td class='py-2 px-3'>{{ formatDate(summary.invoice_date) }}</td>
            <td class='py-2 px-3'>{{ formatDate(summary.deposit_date) }}</td>
            <td class='py-2 px-3 text-right tabular-number'>{{ summary.invoice_id }}</td>
            <td class='py-2 px-3 text-right tabular-number'>{{ formatNumber(summary.invoice_total_amount) }}</td>
            <td class='py-2 px-3 text-right tabular-number'>{{ formatNumber(summary.total_deposit_amount) }}</td>
            <td class='py-2 px-3 text-right tabular-number'>{{ formatNumber(summary.expense_amount) }}</td>
            <td class='py-2 px-3 text-right tabular-number'>{{ formatNumber(summary.associate_amount) }}</td>
            <td class='py-2 px-3 text-right tabular-number'>{{ formatNumber(summary.total_deposit_amount - summary.expense_amount - summary.associate_amount) }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr class='border-b border-gray-200 text-xs font-semibold'>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class='px-2 px-3'>합계</td>
            <td class='py-2 px-3 text-right tabular-number'>{{ formatNumber(subTotal('invoice_total_amount')) }}</td>
            <td class='py-2 px-3 text-right tabular-number'>{{ formatNumber(subTotal('total_deposit_amount')) }}</td>
            <td class='py-2 px-3 text-right tabular-number'>{{ formatNumber(subTotal('expense_amount')) }}</td>
            <td class='py-2 px-3 text-right tabular-number'>{{ formatNumber(subTotal('associate_amount')) }}</td>
            <td class='py-2 px-3 text-right tabular-number'>{{ formatNumber(subTotal('total_deposit_amount') - subTotal('expense_amount') - subTotal('associate_amount')) }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dayjs        from 'dayjs'
import numbro       from 'numbro'
import * as XLSX    from 'xlsx-js-style'

export default {
  name: 'ClientAnnualRevenueDetails',
  computed: {
    ...mapState('companies', [
      'selectedClientPerformances',
    ]),
    clientName () {
      return this.selectedClientPerformances.length > 0 ? this.selectedClientPerformances[0].company_name : ''
    },
  },
  methods: {
    exportExcel () {
      var excelData = XLSX.utils.table_to_sheet(document.getElementById('clientAnnualRevenue')); // table id를 넣어주면된다
      var workBook = XLSX.utils.book_new(); // 새 시트 생성
      XLSX.utils.book_append_sheet(workBook, excelData, '매출현황');  // 시트 명명, 데이터 지정
      XLSX.writeFile(workBook, `${this.clientName}_매출현황.xlsx`); // 엑셀파일 만듬
    },
    formatDate (date) {
      return (date) ? dayjs(date).format('YYYY.MM.DD') : '-'
    },
    formatNumber (num) {
      return numbro(num).format({mantissa: 0, thousandSeparated: true})
    },
    subTotal (column) {
      return this.selectedClientPerformances.reduce((accumulator, result) => accumulator + parseFloat(result[column]), 0)
    },
  },
}
</script>
