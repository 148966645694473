<template>
  <div class='home'>
    <div class='flex flex-row justify-center h-screen'>
      <div class='flex flex-col justify-center items-center'>
        <img :src='logoURL' class='w-40 cursor-pointer' />
        <button
          @click='loginWithRedirect'
          class='mt-8 border-blue-500 border-2 text-blue-700 px-16 py-2 rounded hover:shadow-md'>
          Login
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data () {
    return {
      logoURL: process.env.VUE_APP_CLIENT_BASE_URL + '/logo_reach_horizontal.svg',
      retryTimeout: null
    }
  },
  watch: {
    authCompletedLoading: {
      handler: async function () {
        this.checkLogin()
      },
      immediate: true,
    }
  },
  computed: {
    authCompletedLoading() {
      return this.$auth && !this.$auth.loading
    }
  },
  methods: {
    async loginWithRedirect() {
      await this.$auth.loginWithRedirect()
    },
    async checkLogin() {
      if (this.authCompletedLoading) {
        try {            
          if (this.$auth.isAuthenticated) {
            await this.$router.push({ name: 'Dashboard' })
          } else {
            this.loginWithRedirect()
          }
        } catch (err) {
          console.error('Auth error:', err)
          this.loginWithRedirect()
        }
      } else {
        console.log('Auth not completed')
        this.retryTimeout = setTimeout(() => {
          console.log('Checking login again after 1 second')
          this.checkLogin()
        }, 1000)
      }
    }
  },
  beforeDestroy() {
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout)
    }
  }
}
</script>
