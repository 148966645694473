import Vue             from 'vue'
import Vuex            from 'vuex'
import companiesApi     from '@/api/v1/companies'
import companyFieldValuesApi from '@/api/v1/company_field_values'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  companies: [],
  clientPerformances: [],
  selectedClientPerformances: [],
  drilldownCompanyId: 0,
})


const getters = {
  getField,
}


const actions = {
  resetDrilldownCompany ({ commit }) {
    commit('setDrilldownCompanyId', 0)
  },
  getCompanies ({ commit, dispatch }, filters={}) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
        companiesApi.getCompanies(filters).then(resp => {
        commit('setCompanies', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getCompany ({ dispatch }, companyId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      companiesApi.getCompany(companyId).then(resp => {
        resolve(resp)
        dispatch('deactiveLoading', null, { root : true })
      })
    })
  },
  getCompanyEntities ({ dispatch }, companyId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      companiesApi.getCompanyEntities(companyId).then(resp => {
        resolve(resp)
        dispatch('deactiveLoading', null, { root : true })
      })
    })
  },
  getCompanyCases ({ dispatch }, companyId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      companiesApi.getCompanyCases(companyId).then(resp => {
        resolve(resp)
        dispatch('deactiveLoading', null, { root : true })
      })
    })
  },
  getCompanyFieldValues ({ dispatch }, companyId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      companyFieldValuesApi.getCompanyFieldValuesForCompany(companyId).then(resp => {
        resolve(resp)
        dispatch('deactiveLoading', null, { root : true })
      })
    })
  },
  setCompanyFieldValues ({ dispatch }, fieldValues) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      companyFieldValuesApi.setCompanyFieldValues(fieldValues).then(resp => {
        resolve(resp)
        dispatch('deactiveLoading', null, { root : true })
      })
    })
  },
  getClientPerformances ({ commit, dispatch }, filters) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      companiesApi.getClientPerformances(filters).then(resp => {
        commit('setClientPerformances', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  }
}


const mutations = {
  setCompanies (state, companies) {
    state.companies = companies
  },
  setDrilldownCompanyId (state, companyId) {
    state.drilldownCompanyId = companyId
  },
  setClientPerformances (state, fromApi) {
    state.clientPerformances = fromApi
  },
  setSelectedClientPerformances (state, performances) {
    state.selectedClientPerformances = performances
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
